// Accordion
.ttr-accordion{
	&.style1{
		.accordion-item{
			margin-bottom: 10px;
			box-shadow: unset;
			border: 0;
		}
		.accordion-button{
			padding: 12px 25px;
			border: 1px solid $primary;
			background:$primary;
			text-align: left;
			display: block;
			color: $white;
			font-size: 18px;
			border-radius: 0!important;
			font-family:$font-family-title;
			@include durationMedium;
			cursor:pointer;
			
			i{
				margin-right: 10px;
				color:$white;
			}
			&:active,
			&:hover,
			&:focus{
				box-shadow: unset;
			}
			&.collapsed{
				border-color: #999;
				background-color: $white;
				color: $secondary;
				i{
					color:$primary;
				}
			}			
		}
		.accordion-body{
			background-color: #fff;
			padding: 20px 25px;
			color: $black;
			border-radius:0;
			font-size:15px;
			min-height: 150px;
			border: 1px solid #999;
			border-top: 0px solid #999;
		}
		@include respond('phone'){
			.accordion-button{
				padding: 12px 18px;
				font-size: 18px;
			}
			.accordion-body{
				padding: 15px 18px;
			}
		}
	}
}

/* RTL */
.rtl .ttr-accordion.style1 .accordion-button {
	text-align: right;
	font-family: $font-family-base-ar;
}

.rtl .ttr-accordion.style1 .accordion-button i {
	float: left;
}