/*	
	Author: ThemeTrades
	======================
	Name: Serfix
    Version:    1.1
    Portfolio : https://themeforest.net/user/themetrades/portfolio
	
	======================
	[Table of contents]
	======================
	
	==========
	* Start *
	==========
	
	1. Abstract
		1.1 Mixins
			1.1.1 Responsive
			1.1.2 Transition
			
		1.2 Variable
		
	2. Base
		2.1 Typography
			2.1.1 Banner
			2.1.2 Heading
			2.1.3 Padding Margin
			2.1.4 Spacing
		
		2.2 Custom Grid
		2.3 Fonts
		2.4 Preloader
		2.5 Reset
		
	3. Components
		 3.1 Accordion
		 3.2 Button
		 3.3 Card
		 3.4 Feature Box
		 3.5 Filter
		 3.6 Form
		 3.7 Gallery
		3.08 Iconbox
		3.09 Main Slider
		3.10 Overlay
		3.11 Owl Button
		3.12 Pagination
		3.13 Pricetable
		3.14 Scrolltop
		3.15 Services Menu
		3.16 Table
		3.17 Team
		3.18 Testimonial
		
	4. Layout
		4.1 Header
		4.2 Layout
		4.3 Widget
		4.4 Footer
		
	5. Pages
		5.1 Index
		5.2 Index 2
		5.3 About 1
		5.4 About 2
		5.5 Blog Details
		5.6 Booking
		5.7 Contact
		5.8 Error
		5.9 My Account
		5.10 Services
	
	6. Shortcodes
		6.1 Accordion
		6.2 Alert
		6.3 Breadcrumb
		6.4 List
		6.5 Widget
	
	==========
	* End *
	==========	
*/


@import "./abstract/abstract";
@import "bootstrap/scss/bootstrap.scss";
@import "./base/base";
@import "./components/components";
@import "./pages/pages";
@import "./layout/layout";
@import "./shortcodes/shortcodes";

.VideoBanner{
	position: relative;
	overflow: hidden;
	&::after{
		content: "";
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.7);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	video{
		width: 100%;
		height: 100vh;
		object-fit: cover;
		margin: 0;
		padding: 0;
	}
	
	.BannerContent{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		z-index: 2;
		flex-direction: column;/*New added*/
		text-align: center;
		.container{
			text-align: left;
			.ContentBoxt{
				max-width: 1150px;
			}
			.T1{
				font-weight: 600;
				font-size: 55px;
				line-height: 1.2;
				color: #ffff;
				text-transform: uppercase;
				margin-bottom: 20px;
				span{
					display: inline-block;
					&::after{
						content: "//";
						color: #e01424;
						font-weight: 900;
						transform: scale(0.98) translateY(-3%);
						display: inline-block;
						margin-left: 20px;
					}
					&::before{
						content: "//";
						color: #e01424;
						font-weight: 900;
						transform: scale(0.98) translateY(-3%);
						display: inline-block;
						margin-right: 20px;
					}
				}

			}
			.T2{
				font-size: 18px;
				margin-bottom: 20px;
			}
		}
	}
}

.VideoBanner .BannerContent .container .T1 h1 {
	font-weight: 600;
  font-size: 55px;
  line-height: 1.2;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.BrandBoxMega{
	padding: 20px;
	width: 100%;
	padding-bottom: 0;
	.row{
		.col-md-2{
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding: 15px;
			border-right: 1px solid rgba(0, 0, 0, 0.1);
			flex: 0 0 auto;
    		width: 14.28%;
			display: flex;
			justify-content: center;
			align-items: center;
			a{
				display: block;
			}
			img{
				max-width: 100%;
				height: 70px;
				object-fit: contain;
				padding: 10px;
			}
		}
	}
}

.BrandFaq{
	background-color: #f7f7f7;
	padding: 60px 0px;
}
.header-transparent .menu-links .nav > li:hover > a{
	color: $primary;
}

.BrandPageBox {
	margin-top: 40px;
	.BrandBoxMega{
		padding: 0px;
		
		.row{
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
			.col-md-2{
				width: 20%;
				background-color: #fff;
				img{
					height: 80px;
				}
			}
		}

	}
}
.feature-bx1 {
	.ttr-tilte{
		font-size: 19px;
		/*display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;*/
		overflow: hidden;
	}
}
.bg-light{
	background-color: #efefef !important;
}
.mob-only{
	display: none;
}
.halo-sticky-toolbar-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    box-shadow: 0 0 9px #0000001f;
    transition: transform .6s;
    background: #171a1e;
    border: 1px solid #171a1e;
    overflow: hidden;
  }
  .bottom-bar {
    padding: 3px 15px;
    min-height: 60px;
  }
  .bottom-bar--list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin: 0;
    align-items: center;
  }
  .bottom-bar--list li > a {
    padding: 7px 2px;
    display: block;
    pointer-events: auto;
    text-align: center;
    color: #ffffff;
  }
  .bottom-bar--list .icon img {
    max-width: 25px;
  }
  .bottom-bar--list .icon + .text {
    margin-top: 6px;
    line-height: 1;
    font-size: 11px;
  }
  .DiagonalRowWrap{
	position: relative;
  z-index: 1;
  margin-top: -10px;
	.col-md-3{
		background-color: $primary;
		color: #fff;
		min-height: 350px;
		display: flex;
		align-items: center;
		position: relative;
		line-height: 1.1;
		&::after{
			content: "";
			background-color: rgba(0, 0, 0, 0.5);
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
		&.row1{
			background-image: url(../images/hundredpercentage-min.jpg);
			background-size: cover;
		
		}
		&.row2{
			background-image: url(../images/Dealership-Quality-Repair-min.jpg);
			background-size: cover;
		}
		&.row3{
			background-image: url(../images/Service-Contract-&-Warranty-Plans-min.jpg);
			background-size: cover;
		}
		&.row4{
			background-image: url(../images/Pick-up-across-saudi-min.jpg);
			background-size: cover;
		}
		.DiBox1{
			padding: 20px;
			position: relative;
			z-index: 2;
			.DiBox1T1{
				font-size: 30px;
				font-weight: 700;
				margin-bottom: 10px;
				
			}
			
			.DiBox1T3{
				color: #fff;
				padding: 10px;
				border: 1px solid #fff;
				display: inline-block;
				margin-top: 25px;
				font-weight: 600;
				&:hover{
					background-color: #212121;
				}
			}
		}
		
	}
  }

.ServiceBoxR1{
	background-size: cover;
	min-height: 300px;
	color: #fff;
	position: relative;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	text-align: center;
	margin-bottom: 30px;
	&::after{
		content: "";
		background-color: rgba(0,0,0,0.2);
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 20px;
	}
	>div{
		position: relative;
		z-index: 1;
	}
	.SerT3{
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;  
		overflow: hidden;
		line-height: 1.1;
	}
	
	&.service1{
		background-image: url(../images/n1/BodyandPaint.webp);
	}
	&.service2{
		background-image: url(../images/n1/ElectricCar.webp);
	}
	&.service3{
		background-image: url(../images/n1/MajorService.webp);
	}
	&.service4{
		background-image: url(../images/n1/MinorService.webp);
	}
	&.service5{
		background-image: url(../images/n1/ServiceContract.webp);
	}
	&.service6{
		background-image: url(../images/n1/Mechanical.webp);
	}
	&.service7{
		background-image: url(../images/n1/Recovery.webp);
	}
	&.service8{
		background-image: url(../images/n1/ElectricalRepair.webp);
	}
	.SerT1{
		font-weight: 600;
		font-size: 22px;
		line-height: 1.1;
		text-align: center;
		margin-bottom: 10px;
	}
	.SerT2{
		margin: 0 auto;
		margin-top: 10px;
		max-width: 200px;
			
	}
	&:hover{
		.SerT2{
			height: auto;
			width: auto;
			opacity: 1;
		}
	}
}
.AbtBx1{
	background-image:url(../images/background/bg-map.png) !important;
	background-repeat: repeat !important;
	padding: 60px 0px;
	.heading-bx.text-center{
		max-width: 1200px;
		margin-top: 0;
		margin-bottom: 0;
	}
}
.AbtBx2{
	padding: 60px 0px;
	background-image:url(../images/background/n.png) !important;
	background-repeat: repeat !important;
	.heading-bx.text-center{
		max-width: 1200px;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.AbtBx3{
	padding: 60px 0px;
	.heading-bx.text-center{
		max-width: 1200px;
		margin-top: 0;
		margin-bottom: 0;
	}
}
.IconBox1{
	.counter-style-1{
		text-align: center;
		.serviceicon{
			width: 60px;
			height: 60px;
			margin-bottom: 10px;
		}
		.counter-text {
			font-weight: 500 !important;
		}
	}
}
@media only screen and (max-width: 1200px) {
	.container{
		max-width: 100%;
	}
	.menu-links .nav > li > a {
		font-size: 15px;
		padding: 27px 6px 27px 6px;
	}
	.AbtBx1{
		padding: 20px 0px;
	}
	.AbtBx2{
		padding: 20px 0px;
	}
	.AbtBx3{
		padding: 20px 0px;
	}
}
@media only screen and (max-width: 1000px) {
	.ServiceBoxR1{
		min-height: 220px;
		margin-bottom: 15px;
		.SerT2{
			height: auto;
			width: auto;
			opacity: 1;
		}
		
	}
	.DiagonalRowWrap {
		.col-md-3{
			flex: 0 0 50%;
			max-width: 50%;
			min-height: 280px;
			&::before{
				content: none !important;
			}
			.DiBox1{
				padding: 0px;
				text-align: center;
				.DiBox1T1{
					font-size: 19px;
    				text-transform: capitalize;
				}
				.DiBox1T3{
					margin-top: 15px;
					padding: 7px;
				}
				
			}
		}
	}
	.desk-only{
		display: none;
	}
	.mob-only{
		display: block;
	}
	body, html {
		position: relative;
	  }
	  html {
		width: 100%;
		height: 100%;
		overflow-x: hidden;
	  }
	  body {
		overflow-x: hidden !important;
	  }
	  .VideoBanner .BannerContent .container .T1, .VideoBanner .BannerContent .container .T1 h1{
		font-size: 25px;
	  }
	  .VideoBanner .BannerContent .container .T2 {
		font-size: 14px;
	  }
	  .BannerContent .btn-secondary{
		margin-top: 0px !important;
		margin-left: 0 !important;
	  }
	  .feature-bx1 .ttr-tilte {
		font-size: 17px;
		-webkit-line-clamp: 5;
	  }
	  .section-sp2 {
		padding-top: 30px;
		padding-bottom: 30px;
	  }
	 .BrandBoxMega .row .col-md-2 img {
		height: auto;
		padding: 0;
		width: 100%;
	  }
	 .BrandBoxMega .row .col-md-2 {
		width: 25%;
		background-color: #fff;
	  }
	  .BrandPageBox {
		margin-top: 10px;

	  }
	  .BrandBoxMega{
		padding: 0;
		background-color: #fff;
	  }
	  .BrandFaq {
		background-color: #f7f7f7;
		padding: 20px 0px;
	  }
	  .footer-info-bx.left .icon-lg img {
		height: 40px;
	  }
	  button.back-to-top{
		bottom: 80px;
	  }
	  .section-sp1 {
		padding-top: 20px;
		padding-bottom: 20px;
	  }
	  body{
		font-size: 14px;
	  }
	  h2, .h2 {
		font-size: 28px;
	  }
}
.sticky-header.active .menu-bar .menu-bar {
	background-color: #ffc50a !important;
  }
  .OurBrandsRow{
	background-image:url(../images/background/bg-map.png);
	background-repeat: repeat;
	.BrandBoxMega .row .col-md-2 img{
		height: 85px;
	}
	.BrandBoxMega{
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0;
	}
	.BrandBoxMega .row .col-md-2 {
		padding: 20px;
	}
}
.about-wraper1 ,.BlogWrap1 , .cstBg{
	background-image:url(../images/background/bg-map.png) !important;
	background-repeat: repeat !important;
}
.BrandBoxMega .row .col-md-2 img{
	transition: 0.4s;
}
.BrandBoxMega .row .col-md-2 img:hover{
	transform: scale(1.05);
}
.mb-25{
	margin-bottom: 25px;
}
.list-check-squer.text-left{
	text-align: left;
}
.feature-bx1:hover{
	background-color: #171a1e !important;
}
.serviceicon{
	width: 80px;
	height: 80px;
	display: inline-block;
	background-size: 100% 100%;
}
.service1{
	background-image: url(../images/serviceicon/Mechanical/axil.png);
}
.service2{
	background-image: url(../images/serviceicon/Mechanical/BrakeRepair.png);
}
.service3{
	background-image: url(../images/serviceicon/Mechanical/EngineCooling.png);
}
.service4{
	background-image: url(../images/serviceicon/Mechanical/Gearbox.png);
}
.service5{
	background-image: url(../images/serviceicon/Mechanical/Pre-Purchase.png);
}
.service6{
	background-image: url(../images/serviceicon/Mechanical/SuspensionRepair.png);
}
.service7{
	background-image: url(../images/serviceicon/Mechanical/TransmissioRepair.png);
}
.service8{
	background-image: url(../images/serviceicon/ElectricalRepair/ACRepair.png);
}

.service9{
	background-image: url(../images/serviceicon/ElectricalRepair/CarCameraRadarCalibration.png);
}
.service10{
	background-image: url(../images/serviceicon/ElectricalRepair/CarSoftwareRepandProgramming.png);
}
.service11{
	background-image: url(../images/serviceicon/ElectricalRepair/ComfortSystemRepair.png);
}
.service12{
	background-image: url(../images/serviceicon/ElectricalRepair/EngineControlUniRepair.png);
}
.service13{
	background-image: url(../images/serviceicon/ElectricalRepair/NavigationUpdate.png);
}
.service14{
	background-image: url(../images/serviceicon/ElectricalRepair/Retrofit.png);
}
.service15{
	background-image: url(../images/serviceicon/BodyShop/SmartBodyRepair.png);
}
.service16{
	background-image: url(../images/serviceicon/BodyShop/CarDetailing.png);
}
.service17{
	background-image: url(../images/serviceicon/BodyShop/CarTinting.png);
}
.service18{
	background-image: url(../images/serviceicon/BodyShop/CarDIPColor.png);
}
.service19{
	background-image: url(../images/serviceicon/BodyShop/CeramicCoating.png);
}
.service20{
	background-image: url(../images/serviceicon/BodyShop/BrakeCaliper.png);
}
.service21{
	background-image: url(../images/serviceicon/BodyShop/PaintProtectionFilm.png);
}
.service22{
	background-image: url(../images/serviceicon/BodyShop/WindshieldRepair.png);
}
.service23{
	background-image: url(../images/serviceicon/BodyShop/WindshieldRepair.png);
}
.service24{
	background-image: url(../images/serviceicon/OtherServices/CarRecovery.png);
}
.service25{
	background-image: url(../images/serviceicon/OtherServices/FleetManagement.png);
}
.service26{
	background-image: url(../images/serviceicon/OtherServices/ElectricCarRepair.png);
}
.icon-content p {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;  
	overflow: hidden;
  }
@media only screen and (max-width: 1000px) {
	.mb-25{
		margin-bottom: 15px;
	}
	.OurBrandsRow .BrandBoxMega .row .col-md-2 img {
		height: 45px;
	  }
	  .OurBrandsRow .BrandBoxMega .row .col-md-2 {
		padding: 15px;
	  }
}
.TestWrap .es-main-container > a{
	display: none !important;
}
.Mask{
	background-image:url(../images/background/n.png) !important;
	background-repeat: repeat !important;
	height: 50px;
	/*margin-top: -52px;*/
	background-color: #fff;
	position: relative;
	z-index: 999999;
}
.MaskWrap{
	padding-bottom: 0px !important;
	background-image:url(../images/background/n.png) !important;
	background-repeat: repeat !important;
}
.heading-bx.full{
	max-width: 1000px !important;
}
.MaskWrap11{
	background-image:url(../images/background/n.png) !important;
	background-repeat: repeat !important;
}
.mbg1{
	background-image:url(../images/background/n.png) !important;
	background-repeat: repeat !important;
}
.header-transparent .sticky-header.active .menu-bar ,
.sticky-inner-wrapper,
.header-transparent,
.halo-sticky-toolbar-mobile{
	z-index: 9999999 !important;
}
.No-index .halo-sticky-toolbar-mobile{
	z-index:9 !important;
}
.MaskWrap.MaskWrap120 {
	.Mask{
		/*margin-top: -120px !important;*/
	}
}
@media only screen and (max-width: 1000px) {
	.MaskWrap.MaskWrap120 {
		.Mask{
			margin-top: -60px !important;
			display: none;
		}
	}
	.Mask{
		height: 30px;
	}
	.BtnMobile{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.BtnMobile a{
		flex: 0 0 49%;
		max-width: 49%;
		margin: 0 !important;
		font-size: 11px;
		padding: 10px 2px;
	}

}
.ContentBox2 {
	b{
		font-weight: 800;
	}
}
.ServiceBox1{
	background-image:url(../images/background/bg-map.png);
	background-repeat: repeat;
	background-color: #f7f7f7;
	.row{
		margin: 0 -5px !important;
		.col-12{
			padding: 0 5px !important;
			.counter{
				
			}
			.counter-text{
				font-weight: 400;
			}
		}
	}
	.heading-bx.text-center{
		max-width: 900px;
	}
	.counter-style-1{
		background-color: #fff;
		margin-bottom: 15px;
	}
}
.ActivePanel .halo-sticky-toolbar-mobile, 
.ActivePanel .header-transparent, 
.ActivePanel .header-transparent .sticky-header.active .menu-bar, 
.ActivePanel .sticky-inner-wrapper{
z-index: 9 !important;
}
.counter-style-1 > p{
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}
.ServiceBox1 .heading-bx.text-center {
	max-width: 1200px;
  }
@media only screen and (max-width: 1000px) {
	.counter-style-1 > p{
		height: auto;
		overflow: hidden;
	}
}
.heading-bx .title{
	text-transform: uppercase;
}

.ActivePanel .Mask{
	z-index: 0 !important;
}
.VideoBanner .BannerContent .container .T1 span::before ,
.VideoBanner .BannerContent .container .T1 span::after ,
.heading-bx .title::after ,
.page-banner h1 span::before, 
.page-banner .h1 span::before, 
.page-banner h1 span::after, 
.page-banner .h1 span::after{
	content: none;
}
.form-card .form-group .btn, .form-card .form-group .wp-block-button__link {
	background: #000;
	color: #fff;
	border-color: #fff;
  }
  .progress-box.style1 .progress , .progress-box.style1 .progress-info .progress-value{
	display: none;
  }
  .progress-box.style1 {
	margin-bottom: 10px;
	font-size: 15px;
  }
  .section-sp1 .col-xl-8{
	flex:0 0 100%;
	width: 100%;
  }
  .btng1{
	color:#777777 !important;
  }

  .SideNavSocial ul{
	text-align: right;
  }
  @media only screen and (max-width: 1200px) {
	.menu-links .nav > li > a {
	  font-size: 15px;
	  padding: 20px 6px;
	}
  }
.DiBox1T2{
	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
  @media only screen and (max-width: 991px) {
	.page-banner {
	  height: 80vh;
	}
	.page-banner .h1, .page-banner h1 {
		font-size: 28px;
	  }
  }
  @media only screen and (min-width: 991px) {
	.menu-links .nav > li:hover > .sub-menu {
		display: block !important;
	  }
  }
  .ttr-post-media img {
	width: 100%;
	height: 75px !important;
  }
  .widget-post-bx a{
	margin-bottom: 10px;
	display: block;
  }
  footer .recent-posts-entry .ttr-post-info .post-title a ,
  .recent-posts-entry .ttr-post-info .post-title a {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
  }
  .post-media img {
	object-fit: cover;
  }
  
 /* .post-info .post-content p{
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;  
	overflow: hidden;
  }*/

  .post-info .post-content p{
	display: -webkit-box;
	-webkit-box-orient: vertical; 
  }
  .side-bar .ttr-post-media img {
	width: 100%;
	height: 95px !important;
  }
@media only screen and (max-width: 991px) {
  .menu-links .nav > li:hover > .mega-menu, .menu-links .nav > li:hover > .sub-menu {
	display: block !important;
  }
  .page-banner {
    background-position: left top;
  }
}
.FilterTabFlex{
	display: flex;
	margin: 0 -5px;
	flex-wrap: wrap;
	border-bottom: 1px solid #e4e4e4;
}
.FilterTabColumn{
	flex: 0 0 16.66%;
	max-width: 16.66%;
	text-align: center;
	padding: 0 5px;
}
.FilterItem{
	padding: 10px;
	font-weight: 500;
  color: #000;
  cursor: pointer;
  background-color: #e4e4e4;
}
.BrandPage .ViewAllButtonBox{
	display: none !important;
}

.loader-container {
	display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 100px 0px;
  }
  
  .page-banner.intpage {
	height: 115px;
	background: #0e0f0f;
  }

@media only screen and (max-width: 991px) {
	.page-banner {
	  height: 50vh;
	}

	.page-banner .page-banner-entry {
		padding-top: 30px;
		height: 100%;
	}

	.page-banner.intpage {
		height: 50vh;
	  }
	.DiagonalRowWrap .col-md-3 .DiBox1 .DiBox1T3 {
		display: none;
	  }
	  .DiagonalRowWrap .col-md-3 {
		min-height: 200px;
	  }
	  .FilterTabColumn {
		flex: 0 0 33.33%;
		max-width: 33.33%;
		padding: 0 5px;
		text-align: center;
		margin-bottom: 10px;
	  }
	  .FilterTabFlex{
		justify-content: center;
	  }
  }

  .FilterItem.active{
	color: #e01424;
  }

  .ramadancontainer {
	position: relative;
  }

  .ramadancontainer .ramadanbanner1 {
	position: absolute;
  	right: 0px;
	top: -100px;
	-webkit-animation: up-down 1s infinite  alternate;
	animation: up-down  1s infinite  alternate;
  }

  .ramadanBannerArea {
	margin-bottom: 40px;
  }

  .ramadanBannerArea .ramadanContent {
		text-align: center;
  }

  @media only screen and (max-width: 1225px) {
	.ramadancontainer .ramadanbanner1 {
		display: none;
	}
  }


  /* RTL */

.rtl .VideoBanner .BannerContent .container {
	text-align: right;
  }

.rtl .VideoBanner .BannerContent .container .T2 {
	font-size: 23px;
  }

  .rtl .DiBox1T2 {
	font-size: 20px;
  }

  .rtl .head-text p {
	font-size: 20px;
  }

  .rtl .ServiceBox1 .SerGrid1 .counter-style-1 > p, .rtl .ttr-accordion .accordion-body p {
	font-size: 19px;
  }

  .pagination-bx {
	display: block;
  }
  .en-offerhide, .ar-offerhide {
	display: none;
  }

  .ltl .en-offerhide, .rtl .ar-offerhide {
	display: block;
  }

  .rtl .arhide {
	display: none;
  }

  .displayNone {
	display:none;
  }

  .cstoffers .blog-card {
	margin-bottom: 0px;
	height: 100%;
  }

  .cstoffers .col-xl-4 {
	margin-bottom: 30px;
  }

  .cst-post-info .post-content p{
	-webkit-line-clamp: 4;
	overflow: hidden;
  }

  .offerModalClass .modal-header {
	text-align: center;
  }

  .offerModalClass .modal-header .modal-title {
	width: 100%;
  }

  .offerModalClass .form-control, .offerModalClass .form-select {
	border: 1px solid #cacaca;
  padding: 10px 20px;
  border-radius: 0;
  height: 50px;
  box-shadow: none;
  outline: none;
  color: #777777;
}

.offerModalClass .formOfferText {
	display:none;
}

.offerModalClass .modal-header .btn-close:focus {
	box-shadow: none !important;
  	outline: none !important;
}

.rtl .offerModalClass .form-select {
	background-position: left 0.75rem center !important;
	padding: 0.375rem 0.75rem 0.375rem 2.25rem;
}


  /*
  .rtl .cstoffers .offercard .post-info .post-title {
	font-size: 24px;
  }

  .rtl .cstoffers .offercard .post-info .post-content p{
	font-size: 21px;
  }

  .rtl .cstoffers .offercard .post-info .btn-primary {
	font-size: 20px;
  }*/

  @media only screen and (max-width: 417px) {
	
	.rtl .BtnMobile .btn {
		font-size: 16px;
	}

	.BtnMobile {
		justify-content: center;
		flex: unset;
		max-width: none;
		gap: 20px;
	}
	
	.BtnMobile a.btn-primary {
		width: 180px;
		flex: unset;
		max-width: none;
	}
	
	.BtnMobile a.btn-secondary {
		width: 90px;
		flex: unset;
		max-width: none;
	}

  }