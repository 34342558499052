.btn {
    padding: 7px 20px;
    display: inline-block;
    border-radius: 0;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
	font-family:$font-family-title;
	font-weight: 600;
}

.rtl .btn {
	font-family: $font-family-base-ar;
}
.btn-primary{
    color: #fff;
	&:hover{
		color: #fff;
	}
}
.btn-language{
	background-color: #fff;
	color: $secondary;
	position: relative;
	padding-right: 40px;
	display: inline-block;
	font-family: 'Almarai', sans-serif;
	.flag1{
		background-image: url(../../images/flag1.png);
		width: 25px;
		height: 15px;
		display: inline-block;
		background-size: 100% 100%;
		border-radius: 5px;
		position: absolute;
		top: 12px;
  		right: 10px;
	}
	&:hover{
		background-color: $secondary;
		color: #fff;
	}
}

header .active {
	.btn-language{
		background-color: $secondary !important;
		color: #fff !important;
	}
}

.btn-outline-primary{
	&:hover{
		color: #fff;
	}
}
.btn.btn-lg{
	padding: 10px 35px;
    font-size: 16px;
}
.btn-link{
	font-weight: 600;
	color: $primary;
	font-size: 15px;
	border: 0;
	position: relative;
	text-decoration: none;
	
	&:after{
		content: "";
		height: 2px;
		width: 25px;
		position: absolute;
		background-color: $primary;
		bottom: 0;
		left: 0;
		@include durationMedium;
	}
	&:hover{
		text-decoration:none;
		&:after{
			width: 100%;
		}
	}
}

.rtl .btn-link::after {
	left:unset;
	right: 0;
}