section{
    position: relative;
}
.title-ext{
	text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
}
.heading-bx{
	margin-bottom:50px;
	@include respond('tab-land'){
		margin-bottom:40px;
	}
	@include respond('phone'){
		margin-bottom:35px;
	}
	&.text-white{
		h1, h2, h3, h4, h5, h6{
			color:$white;
			&:after{
				color:$white;
			}
		}
	}
	&.mb-40{
		margin-bottom:40px;
	}
	&.mb-30{
		margin-bottom:30px;
	}
	&.mb-20{
		margin-bottom:20px;
	}
	.title{
		&:after{
			content: "//";
			color: #e01424;
			font-weight: 900;
			transform: scale(0.93) translateY(-3%);
			display: inline-block;
			margin-left: 5px;
		}
	}
	p{
		padding-top:10px;
		font-size: 16px;
		@include respond('tab-land'){
			font-size:14px;	
		}
		
		
	}
	&.text-center{
		max-width:750px;
		margin-left:auto;
		margin-right:auto;
	}
	&.head-col{
		display:flex;
		align-items: center;	
		@include respond('phone-land'){
			display:block;
		}
		.title{
			margin-bottom:0;
			@include respond('phone-land'){
				margin-bottom:10px;
			}
		}
		p{
			padding-left: 15px;
			border-left: 4px solid $primary;
			margin-left: 15px;
			margin-bottom: 0;
			@include respond('phone-land'){
				padding-left: 0;
				border-left:0;
				margin-left: 0;
			}
		}
	}
}
.text-white{
	h1, h2, h3, h4, h5, h6{
		color:$white;
	}
}

/* RTL */

.rtl .heading-bx p {
	font-size: 20px;
}