// feature-bx1
.feature-bx1{
	padding: 40px 30px;
    border-radius: 0;
    background-color: #fff;
    @include durationMedium;
    z-index: 1;
	text-align: center;
	.ttr-tilte,
	p{
		margin-bottom: 15px;
	}
	.ttr-tilte,
	a,
	i,
	p{
		@include durationMedium;
	}
	&:hover{
		background:darken($primary, 5);
		color:$white;
		
		.ttr-tilte,
		i,
		a,
		p{
			color:$white;
		}
		
		[fill]{
			fill: $white;
		}
		.btn-link:after{
			background:$white;
		}
	}
	.icon-lg {
		line-height: 1;
		margin-bottom: 24px;
	}
	@include respond('phone'){
		padding: 35px 30px;
	}
}

// feature-bx2
.feature-bx2{
	border: 1px solid #b4b4b4;
	padding: 35px 25px;
    border-radius: 0;
    z-index: 1;
    @include durationMedium;
    .icon-lg {
		line-height: 1;
		margin-bottom: 24px;
	}
	.ttr-tilte,
	p{
		margin-bottom: 15px;
	}
	.ttr-tilte,
	a,
	p{
		@include durationMedium;
	}
	
	&:after{
		content: "";
		border-top: 15px solid var(--primary);
		border-left: 15px solid transparent;
		position: absolute;
		right: 5px;
		top: 5px;
	}
	&[class*="bg-"]{
		border: 0;
		p,
		.ttr-tilte,
		a,
		i{
			color: #fff;
		}
		&:after{
			border-top-color: #fff;
		}
		.btn-link:after{
			background-color: #fff;
		}
	}
	&.bg-warning{
		p,
		.ttr-tilte,
		a,
		i{
			color: #000;
		}
		&:after{
			border-top-color: #000;
		}
		.btn-link:after{
			background-color: #000;
		}
	}
}

.linktree .page-banner {
	background-position: center center;
}

.linktree .page-banner::before {
	opacity: 0.6;
}

.linktree .ltheaderText {
	padding: 150px 0px 100px 0px;
  	text-align: center;
	font-size: 24px;
	color: #fff;
	font-weight: 600;
}

.linktree .page-banner .page-banner-entry {
	height: 300px;
}
.linktree .section-sp1 {
	padding-top: 50px;
}
.linktree .section-sp1 .feature-bx1 .icon-lg {
	margin-bottom: 0;
}
.linktree .section-sp1 .feature-bx1 .icon-lg svg {
	width: 35px;
	height: 35px;
}
.linktree .section-sp1 .feature-bx1 .icon-lg i {
	font-size: 40px;
}
.linktree .section-sp1 .feature-bx1 {
	padding: 20px 30px;
	display: flex;
}
.linktree .section-sp1 .feature-bx1 .ttr-tilte {
	margin-bottom: 0;
	color: #fff;
}
.icon-content {
	align-content: center;
	width: 100%;
	text-align: center;
}
.linktree .section-sp1 .feature-bx1 {
	background-color: #e01424;
	color: #fff;
}

.linktree .section-sp1 .cstcolor1 {
	background-color: #a0a0a0;
}

.linktree .section-sp1 .cstcolor2 {
	background-color: #868686;
}

.linktree .section-sp1 .cstcolor3 {
	background-color: #666;
}

.linktree .section-sp1 .cstcolor4 {
	background-color: #555;
}

.linktree .section-sp1 .cstcolor5 {
	background-color: #3a3a3a;
}

.linktree .section-sp1 .feature-bx1:hover {
	background-color: #171a1e !important;
	color: #fff;
}



@media only screen and (max-width: 600px) {
	.linktree .page-banner {
		height: auto;
	}
	.linktree .ltheaderText {
		font-size: 18px;
		padding: 100px 20px 70px 20px;
	}
	.linktree .page-banner .page-banner-entry {
		height: auto;
	}
	.linktree .section-sp1 {
		padding-top: 20px;
	}
	.linktree .section-sp1 .mb-30 {
		margin-bottom: 15px;
	}
	.linktree .section-sp1 .feature-bx1 {
		padding: 10px 20px;
	}
	.linktree .section-sp1 .feature-bx1 .icon-lg {
		margin-right: 0;
	}
	.linktree .section-sp1 .feature-bx1 .icon-content {
		padding-left: 0;
	}
	.linktree .section-sp1 .feature-bx1 .icon-lg i {
		font-size: 30px;
	}
}

@media (min-width: 768px) { 
	.rtl .offset-md-3 {
		margin-right: 25%;
    	margin-left: 0;
	  }
}